import {
  dataValueFromStoreSetting,
  valueFromStoreSetting,
} from "@/services/ceSettings/ceSettingsService";
import { globalConfig } from "@/services/globalConfig/globalConfigService";
import { ContentElementRichTextStoreSetting } from "@/types/ceSettings/ceSettings";
import { HorizontalPlacement, VerticalPlacement } from "@/types/config/enums";
import { ResponsiveValues } from "@/types/globalConfig/globalConfig";
import { STORE_COLOR_CLASS_PREFIX } from "@/utils/constants";
import {
  lowerBreakpointDesktopPx,
  lowerBreakpointMobilePx,
  lowerBreakpointTablet,
  lowerBreakpointTabletPx,
  lowerBreakpointWqhdPx,
} from "@/utils/util";
import { CarouselItemProps } from "./carouselItem";

/**
 * This is the scoped style of a CE.
 *
 * @param props
 * @returns
 */

interface CarouselItemStyleScopedProps extends CarouselItemProps {
  scopedSelector: string;
  imageMinHeight: ResponsiveValues;
}

export default function CarouselItemStyleScoped(
  props: CarouselItemStyleScopedProps
) {
  const scalingfactorSpaceXMobile =
    globalConfig?.layout.scalingfactorSpaceX.mobile ?? 0.25;
  const scalingfactorSpaceXTablet =
    globalConfig?.layout.scalingfactorSpaceX.tablet ?? 0.5;
  const scalingfactorSpaceX2kplus =
    globalConfig?.layout.scalingfactorSpaceX.wqhd ?? 1.25;

  const scalingfactorSpaceYMobile =
    globalConfig?.layout.scalingfactorSpaceY.mobile ?? 0.25;
  const scalingfactorSpaceYTablet =
    globalConfig?.layout.scalingfactorSpaceY.tablet ?? 0.5;
  const scalingfactorSpaceY2kplus =
    globalConfig?.layout.scalingfactorSpaceY.wqhd ?? 1.25;

  const containerColorId = valueFromStoreSetting(
    props.content.cfgStrContainerColor
  );

  const containerBorderColorId = valueFromStoreSetting(
    props.content.cfgStrBorderColor
  );

  const getCaptionPosition = (
    captionContainerOffset: number,
    alignmentX: HorizontalPlacement,
    alignmentY: VerticalPlacement
  ) => {
    const offset = (captionContainerOffset ?? 0) + "px";
    let left = "50%";
    let translateX = "-100%";
    let bottom = "50%";
    let translateY = "100%";

    switch (alignmentX) {
      case "left":
        left = offset;
        translateX = "0px";
        break;
      case "right":
        left = `calc(100% - ${offset})`;
        break;
      default:
        translateX = "-50%";
        break;
    }

    switch (alignmentY) {
      case "bottom":
        bottom = offset;
        translateY = "0px";
        break;
      case "top":
        bottom = `calc(100% - ${offset})`;
        break;
      default:
        translateY = "50%";
        break;
    }

    return `bottom: ${bottom}; left: ${left}; transform: translate(${translateX}, ${translateY});`;
  };

  const richtextStoreSetting = dataValueFromStoreSetting(
    props.ceSettings?.richtext,
    undefined
  ) as ContentElementRichTextStoreSetting;

  const pFontSize = dataValueFromStoreSetting(
    richtextStoreSetting?.ptypography,
    undefined
  )?.fontSize;



  return (
    <style jsx global>
      {`
        :global(.${props.scopedSelector}).carousel-caption {
          .pb-richtext {
            white-space: normal;
            padding-bottom: 0;
          }
        }

        :global(.${props.scopedSelector}).carousel-image-container {
          @media screen and (max-width: ${globalConfig?.responsive?.breakpoints
              ?.mobile}px) {
            min-height: ${props.imageMinHeight?.mobile
              ? `${props.imageMinHeight.mobile}px`
              : "auto"};

            .cms-image {
              min-height: ${props.imageMinHeight?.mobile
                ? `${props.imageMinHeight.mobile}px`
                : "auto"};
            }
          }

          @media screen and (min-width: ${lowerBreakpointTabletPx}) {
            min-height: ${props.imageMinHeight?.tablet
              ? `${props.imageMinHeight.tablet}px`
              : "none"};
            .cms-image {
              min-height: ${props.imageMinHeight?.tablet
                ? `${props.imageMinHeight.tablet}px`
                : "auto"};
            }
          }

          @media screen and (min-width: ${lowerBreakpointDesktopPx}) {
            min-height: ${props.imageMinHeight?.desktop
              ? `${props.imageMinHeight.desktop}px`
              : "auto"};
            .cms-image {
              min-height: ${props.imageMinHeight?.desktop
                ? `${props.imageMinHeight.desktop}px`
                : "auto"};
            }
          }

          @media screen and (min-width: ${lowerBreakpointWqhdPx}) {
            min-height: ${props.imageMinHeight?.wqhd
              ? `${props.imageMinHeight.wqhd}px`
              : "auto"};
            .cms-image {
              min-height: ${props.imageMinHeight?.wqhd
                ? `${props.imageMinHeight.wqhd}px`
                : "auto"};
            }
          }

          .image-shadow {
            height: 1px;
            position: absolute;
            width: 100%;
            box-shadow: ${" 0px " +
            "0px " +
            Math.floor(props.ceSettings?.height.mobile / 3) +
            "px " +
            Math.floor(props.ceSettings?.height.mobile / 3) +
            "px black"};
            @media (min-width: ${lowerBreakpointTabletPx}) {
              box-shadow: ${" 0px " +
              "0px " +
              Math.floor(props.ceSettings?.height.tablet / 3) +
              "px " +
              Math.floor(props.ceSettings?.height.tablet / 3) +
              "px black"};
            }
            @media (min-width: ${lowerBreakpointDesktopPx}) {
              box-shadow: ${" 0px " +
              "0px " +
              Math.floor(props.ceSettings?.height.desktop / 3) +
              "px " +
              Math.floor(props.ceSettings?.height.desktop / 3) +
              "px black"};
            }
            @media (min-width: ${lowerBreakpointWqhdPx}) {
              box-shadow: ${" 0px " +
              "0px " +
              Math.floor(props.ceSettings?.height.wqhd / 3) +
              "px " +
              Math.floor(props.ceSettings?.height.wqhd / 3) +
              "px black"};
            }
          }
        }
        :global(.${props.scopedSelector}).mui-button-wrapper {
          text-align: ${props.ceSettings?.buttonPlacement ?? "left"};
        }

        @media all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) and (max-width: ${lowerBreakpointTablet}) {
          .selector:not(*:root),
          .carousel-item .carousel-image-container .image-shadow {
            box-shadow: 0px 0px 200px 200px rgba(0, 0, 0, 0.8);
          }
        }

        :global(.${props.scopedSelector}).carousel-caption {
          @mixin text($color) {
            color: $color !important;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }

          .button-wrapper {
            margin-top: ${props.ceSettings?.buttonMarginTop *
            scalingfactorSpaceYMobile}px !important;
          }

          background-color: ${containerColorId
            ? `color-mix(in srgb, var(--${STORE_COLOR_CLASS_PREFIX}${containerColorId}), transparent ${props.ceSettings?.captionContainerTransparentPercentage}%);`
            : `rgba(0, 0, 0, ${props.ceSettings?.captionContainerTransparentPercentage}%)`};
          height: ${props.ceSettings?.captionContainerHeightPercentage}%;
          margin-bottom: 0;
          padding-left: ${props.ceSettings?.captionContainerPadding.x *
          scalingfactorSpaceXMobile}px;
          padding-right: ${props.ceSettings?.captionContainerPadding.x *
          scalingfactorSpaceXMobile}px;
          padding-top: ${props.ceSettings?.captionContainerPadding.y *
          scalingfactorSpaceYMobile}px;
          padding-bottom: ${props.ceSettings?.captionContainerPadding.y *
          scalingfactorSpaceYMobile}px;

          .title,
          .teaser {
            text-align: ${props.ceSettings?.captionContainerHeadingsAlign};
          }

          .pb-richtext {
            text-align: initial;
          }

          width: ${props.ceSettings?.captionContainerWidth};
          max-width: 100%;
          ${props.ceSettings?.captionContainerBorder ?? "border: 0;"}
          ${!props.ceSettings?.captionContainerBorderTop
            ? "border-top: none;"
            : ""}
          ${!props.ceSettings?.captionContainerBorderRight
            ? "border-right: none;"
            : ""}
          ${!props.ceSettings?.captionContainerBorderBottom
            ? "border-bottom: none;"
            : ""}
          ${!props.ceSettings?.captionContainerBorderLeft
            ? "border-left: none;"
            : ""}

          ${containerBorderColorId
            ? `border-color: var(--${STORE_COLOR_CLASS_PREFIX}${containerBorderColorId});`
            : ""}

          .teaser {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            margin: 0, 0, 1rem, 0;
            hyphens: auto;
            @media (min-width: ${lowerBreakpointMobilePx}) {
              word-break: break-word;
              hyphens: none;
            }
          }

          .title {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            margin-bottom: 1rem;
            hyphens: auto;
            @media (min-width: ${lowerBreakpointDesktopPx}) {
              word-break: break-word;
              hyphens: none;
            }
          }

          .pb-richtext {
            margin-bottom: 1rem;
          }

          width: 90%;
          bottom: 50%;
          left: 50%;
          transform: translate(-50%, 50%);
          @media (min-width: ${lowerBreakpointTabletPx}) {
            width: ${props.ceSettings?.captionContainerWidth};
            padding-top: ${props.ceSettings?.captionContainerPadding.y *
            scalingfactorSpaceYTablet}px;
            padding-bottom: ${props.ceSettings?.captionContainerPadding.y *
            scalingfactorSpaceYTablet}px;
            padding-left: ${props.ceSettings?.captionContainerPadding.x *
            scalingfactorSpaceXTablet}px;
            padding-right: ${props.ceSettings?.captionContainerPadding.x *
            scalingfactorSpaceXTablet}px;

            .button-wrapper {
              margin-top: ${props.ceSettings?.buttonMarginTop *
              scalingfactorSpaceYTablet}px !important;
            }
            .teaser,
            .title {
              -webkit-line-clamp: 2;
            }
            ${getCaptionPosition(
              props.ceSettings.captionContainerOffset,
              props.ceSettings.alignmentX,
              props.ceSettings.alignmentY
            )}
          }

          @media (min-width: ${lowerBreakpointDesktopPx}) {
            padding-top: ${props.ceSettings?.captionContainerPadding.y}px;
            padding-bottom: ${props.ceSettings?.captionContainerPadding.y}px;
            padding-left: ${props.ceSettings?.captionContainerPadding.x}px;
            padding-right: ${props.ceSettings?.captionContainerPadding.x}px;

            .button-wrapper {
              margin-top: ${props.ceSettings?.buttonMarginTop}px !important;
            }
            .teaser,
            .title {
              -webkit-line-clamp: 4;
            }
          }
          @media (min-width: ${lowerBreakpointWqhdPx}) {
            padding-top: ${props.ceSettings?.captionContainerPadding.y *
            scalingfactorSpaceY2kplus}px;
            padding-bottom: ${props.ceSettings?.captionContainerPadding.y *
            scalingfactorSpaceY2kplus}px;
            padding-left: ${props.ceSettings?.captionContainerPadding.x *
            scalingfactorSpaceX2kplus}px;
            padding-right: ${props.ceSettings?.captionContainerPadding.x *
            scalingfactorSpaceX2kplus}px;

            .button-wrapper {
              margin-top: ${props.ceSettings?.buttonMarginTop *
              scalingfactorSpaceY2kplus}px !important;
            }

            .teaser,
            .title {
              -webkit-line-clamp: 4;
            }
          }
        }

        :global(.${props.scopedSelector}).hide-in-mobile,
        :global(.${props.scopedSelector}).carousel-caption .hide-in-mobile {
          display: none;
          @media (min-width: ${lowerBreakpointTabletPx}) {
            display: inherit;
          }
        }
      `}
    </style>
  );
}
